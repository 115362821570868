import React, { useContext, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import { useParams } from '@reach/router';

import PodcastHeaderSection from '../../components/podcast/PodcastHeaderSection';
import PodcastNavigation from '../../components/podcast/PodcastNavigation';
import AppButton from '../../components/AppButton';
import AppRIIcon from '../../components/AppRIIcon';
import AppTooltip from '../../components/AppTooltip';
import AppTabTitle from '../../components/AppTabTitle';
import AppAudioPlayer from '../../components/AppAudioPlayer';
import AppVideoPlayer from '../../components/AppVideoPlayer';
import { PodcastPageContext } from './Podcast';
import { podcastHelpers } from '../../components/podcast/podcastHelpers';
import DownloadPdf from '../../components/podcast/pdf/DownloadPdf';
import ReCaptcha from '../../components/ReCaptcha';

const PodcastDetails = ({ type }) => {
	const { podcasts } = useContext(PodcastPageContext);
	const { slug } = useParams();

	const { relatedItems } = podcastHelpers;

	const podcast = podcasts.find((item) => item.slug === slug);

	const [isCopied, setIsCopied] = React.useState(false);
	const [openDownloadPdf, setOpenDownloadPdf] = React.useState(false);
	const captchaRef = useRef('captcha');

	useEffect(() => {
		if (!podcast) navigate('/wg-podcast-post');
	}, [podcast]);

	const handleOnClickRelated = (relatedPost) => {
		if (relatedPost?.url) return window.open(relatedPost?.url);

		navigate(`/wg-podcast-post/${relatedPost?.slug}`, {
			replace: true,
		});
	}
	const handleKeyDownRelated = (e, relatedPost) => {
		switch (e.key) {
			case "Enter":
			case " ":
				handleOnClickRelated(relatedPost);
				break;
			default:
				return;
		}
		e.stopPropagation();
		e.preventDefault();
	};
	const handleDownloadPdfClick = () => setOpenDownloadPdf(true);
	const handleKeyDownPdf = (e) => {
		switch (e.key) {
			case "Enter":
			case " ":
				handleDownloadPdfClick();
				break;
			default:
				return;
		}
		e.stopPropagation();
		e.preventDefault();
	}

	const renderRelatedEpisodes = () =>
		podcast?.related_posts &&
		podcast.related_posts.map((relatedPost, key) => (
			<div
				role="link"
				key={key}
				tabIndex={0}
				className={'podcast-related-items w-1/2'}
				onKeyDown={e => handleKeyDownRelated(e, relatedPost)}
				onClick={() => handleOnClickRelated(relatedPost)}
			>
				<h2>{relatedPost?.title}</h2>
			</div>
		));

	const renderRelatedItem = (item, key) => (
		<div className={'flex flex-col items-center justify-between podcast-related-ad pt-6 pb-8'} key={key}>
			<img src={item?.img} alt='' className={'rounded-md w-9/12'} />

			<div className={'flex flex-col justify-center items-center w-full md:w-4/5'}>
				<h2 className={'font-semibold md:font-bold text-lg md:text-xl text-center text-primary py-2'}>
					{item?.title}
				</h2>
				<AppButton
					className={'btn btn-primary w-full'}
					onClick={() => window.open(item?.url)}
					text={`${item?.button}`}
				/>
			</div>
		</div>
	);

	const copyTextToClipboard = (podcast) => {
		const text = document.getElementById('podcast-share').innerText;
		navigator.clipboard.writeText(text);
		setIsCopied(true);

		if (!window.dataLayer) return;

		window.dataLayer.push({
			event: 'share',
			share: {
				content_type: 'working_genius_podcast',
				item_id: `${podcast?.episode_no}_${podcast?.id}_${podcast?.slug}`,
			},
		});
	};

	const renderHTML = (escapedHTML) =>
		React.createElement('div', { dangerouslySetInnerHTML: { __html: escapedHTML } });

	const getMetaDescription = (escapedHTML) => {
		const parser = new DOMParser();
		const parsedHtml = parser?.parseFromString(escapedHTML, 'text/html');
		const excerpt = parsedHtml?.getElementsByTagName('p')[0]?.innerText;
		if (excerpt) {
			return excerpt;
		}
		return 'The 6 Types of Working Genius Podcast with Patrick Lencioni';
	};

	return (
		<div className={'flex flex-col md:flex-row justify-evenly container pt-6 my-4 md:my-6 lg:my-8'}>
			<AppTabTitle
				title={`${podcast?.title} | The 6 Types of Working Genius`}
				description={getMetaDescription(podcast?.excerpt)}
			/>

			<PodcastNavigation type={type} />

			<div className={'podcast-details-content'}>
				<PodcastHeaderSection type={type} podcast={podcast} />
				<div className={'podcast-details'}>
					<div className={'podcast-details-download-button'}>
						<h2 className={'text-2xl font-semibold'}>Listen Now</h2>
						<span className='download-pdf-button' onClick={handleDownloadPdfClick} role="button" tabIndex={0} onKeyDown={handleKeyDownPdf}>
							<AppRIIcon icon={'download-2-line'} className={'pr-1'} />
							DOWNLOAD PDF
						</span>
					</div>

					<div className={'bg-primary p-5 rounded-md mt-4 mb-16 items-center-'}>
						<AppAudioPlayer
							customClassName={'md:w-full'}
							title={podcast?.title}
							audioSrc={podcast?.mp3_link}
						/>
					</div>

					{!!podcast?.vimeo_url && (
						<>
							<span className={'text-2xl font-semibold mb-4'}>Watch Now</span>
							<div className={'mb-8 pb-6'}>
								<AppVideoPlayer videoUrl={podcast?.vimeo_url} />
							</div>
						</>
					)}

					<h2 className={'text-2xl font-semibold pb-2 mb-8'}>Episode Summary</h2>
					<div className={'text-lg mb-8 break-words podcast-content'}>{renderHTML(podcast?.content)}</div>

					<div className={'flex flex-col w-auto '}>
						<h2 className={'text-2xl font-semibold pb-3 mt-4'}>Share</h2>
						<div className={'flex flex-row'}>
							<span
								id={'podcast-share'}
								className='flex items-center border-2 p-2 podcast-share-link w-auto'
							>
								{window.location.href}
							</span>

							<AppTooltip
								type={'small'}
								placement={'bottom-start'}
								content={() => {
									if (isCopied)
										return (
											<p className={'text-white text-tiny'}>Successfully copied to clipboard</p>
										);

									return null;
								}}
								wrapperClass={'flex flex-row items-center'}
							>
								<AppButton
									text={
										<span className={'flex flex-row items-center'}>
											<AppRIIcon icon={'link'} className={'text-xl font-bold pr-2'} /> Copy Link
										</span>
									}
									onClick={() => copyTextToClipboard(podcast)}
								/>
							</AppTooltip>
						</div>
					</div>
				</div>

				{podcast?.related_posts && podcast.related_posts.length > 0 && (
					<div className={'podcast-details mt-3'}>
						<span className={'text-lg font-semibold pb-4'}>Related Episodes</span>
						<div className={'flex flex-col'}>{renderRelatedEpisodes()}</div>
					</div>
				)}

				<div className={'podcast-details mt-3 mb-10'}>
					<div className='grid grid-cols-1 md:grid-cols-2 gap-6 my-3 md:my-8'>
						{relatedItems.map((item, key) => renderRelatedItem(item, key))}
					</div>
				</div>
			</div>

			<DownloadPdf
				podcast={podcast}
				isOpen={openDownloadPdf}
				closeModal={() => setOpenDownloadPdf(false)}
				captchaRef={captchaRef}
			/>

			<ReCaptcha ref={captchaRef} />
		</div>
	);
};

export default PodcastDetails;
