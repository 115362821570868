import React from 'react';
import { cover_logo, gear_student } from '../../../../images';
import { formatNow } from '../../../../services/UtilityService';

const Footer = ({ page }) => {
    return (
        <div className='PageFooter'>
            <div className='PageFooter__layout'>
                <div className='PageFooter__logo'>
                    <img src={cover_logo} alt={''} />
                </div>

                <div className='PageFooter__textContainer'>
                    <p className='PageFooter__paragraph'>
                        <a href='https://WorkingGenius.com'>WorkingGenius.com</a>
                    </p>
                    <p className='PageFooter__paragraph'>
                        &copy; Copyright {formatNow()} The Table Group, Inc.
                    </p>
                </div>

                <div className='PageFooter__pageContainer'>
                    <div className='PageFooter__page'>
                        <p className={'items-center justify-center'}>{page}</p>
                        <div className='PageFooter__pageGear'>
                            <img src={gear_student} alt={''} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
