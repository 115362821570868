import React from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/Layout';
import AppInput from '../../components/AppInput';
import AppButton from '../../components/AppButton';
import { emailRegex, formatDate } from '../../services/UtilityService';
import AppText from '../../components/AppText';
import AppWarningMessage from '../../components/AppWarningMessage';
import AppRIIcon from '../../components/AppRIIcon';
import CustomerService from '../../services/CustomerService';
import RosterService from '../../services/RosterService';
import ReCaptcha from '../../components/ReCaptcha';
import WgIcon from '../../components/WgIcon';

const Find = () => {
	const [loading, setLoading] = React.useState(false);
	const [assessments, setAssessments] = React.useState([]);
	const [hasAccount, setHasAccount] = React.useState(false);
	const [error, setError] = React.useState({});
	const [email, setEmail] = React.useState('');
	const [feedback, setFeedback] = React.useState({
		success: false,
		error: false,
		icon: '',
		iconColor: '',
		title: '',
		description: '',
		info: '',
		ctaText: '',
		ctaAction: '',
	});
	const captchaRef = React.useRef('captcha');

	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const redirectToRequestAssessment = () => {
		navigate('/client/request-assessments/', { replace: true });
	};

	const search = async (payload) => {
		setEmail(payload.email);
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setLoading(true);
		RosterService.find(payload.email, recaptchaValue)
			.then(({ _assessments, _hasAccount }) => {
				setAssessments(_assessments);
				setHasAccount(_hasAccount);
				if (_assessments.length === 0) {
					setError({
						message:
							'We are sorry but we were unable to find a Working Genius Assessment associated with the email address',
					});
				} else {
					setError({});
				}
			})
			.catch((e) => {
				setAssessments([]);
				setError(e);
				setHasAccount(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const _submit = () => {
		if (hasAccount) {
			redirectToRequestAssessment();
		} else {
			sendInvite();
		}
	};

	const sendInvite = (resend = false) => {
		setLoading(true);
		CustomerService.invite({
			roster_id: assessments[0].id,
			assessment_id: assessments[0].assessment,
		})
			.then(() => {
				setLoading(false);
				setFeedback({
					success: true,
					error: false,
					icon: 'mail-send-line',
					iconColor: 'text-green-600',
					title: resend
						? t('assessment.take.confirmation.create_account.resent_title')
						: t('assessment.take.confirmation.create_account.sent_title'),
					description: t('assessment.take.confirmation.create_account.sent_description'),
					info: t('assessment.take.confirmation.create_account.not_received'),
					ctaText: t('assessment.take.confirmation.create_account.resend_invite'),
					ctaAction: () => sendInvite(true),
				});
			})
			.catch(() => {
				setFeedback({
					success: false,
					error: true,
					icon: 'mail-close-line',
					iconColor: 'text-red-600',
					title: t('assessment.take.confirmation.create_account.not_sent_title'),
					description: t('assessment.take.confirmation.create_account.not_sent_description'),
					info: t('assessment.take.confirmation.create_account.not_received'),
					ctaText: t('assessment.take.confirmation.create_account.resend_invite'),
					ctaAction: () => sendInvite(true),
				});
				setLoading(false);
			});
	};

	const renderFooter = () => {
		if (!error.message) {
			return (
				<div className={'flex items-center justify-center'}>
					<span
						className={'flex flex-col items-center text-secondary font-medium my-2 cursor-pointer'}
						onClick={() => navigate('/client/login/', { replace: true })}
					>
						Back to Login
					</span>
				</div>
			)
		}
	}

	const renderContent = () => {
		if (feedback.success || feedback.error) {
			return (
				<div className={'flex flex-col justify-center mx-4 pt-16'}>
					<AppRIIcon icon={feedback.icon} className={`${feedback.iconColor} text-6xl text-center pb-4`} />
					<h1 className={'text-primary text-xl text-center font-bold mb-4'}>{feedback.title}</h1>
					<p className='text-primary text-center w-160 max-w-full mx-auto'>{feedback.description}</p>
					{feedback.info && <p className={'text-center text-secondary mt-4 mb-1'}>{feedback.info}</p>}
					<AppButton
						loading={loading}
						className={'btn btn-primary w-96 max-w-full mx-auto'}
						text={feedback.ctaText}
						onClick={feedback.ctaAction}
					/>
					<ReCaptcha ref={captchaRef} />
				</div>
			);
		}
		return (
			<div className='mt-8 mb-4 mx-auto px-4 md:px-6 py-4 bg-white' style={{ maxWidth: '28rem' }}>
				{error && error.message && !hasAccount && (
					<AppWarningMessage
						title={
							'We are sorry but we were unable to find a Working Genius Assessment associated with the email address'
						}
						message={'Please enter the email address used when you took the Working Genius Assessment.'}
					/>
				)}

				<h1 className='text-secondary text-2xl text-center font-semibold mb-4'>
					Find your <br /> Working Genius Assessment
				</h1>

				{hasAccount && assessments.length === 0 && (
					<div className={'flex flex-col items-center justify-center'}>
						<div className='flex flex-1'>
							<div className={'relative'}>
								<AppRIIcon icon={'survey-line'} className={'text-3xl'} />
								<div className={'absolute'} style={{ bottom: '8px', right: '8px' }}>
									<AppRIIcon icon={'search-2-line'} className={'text-10'} />
								</div>
							</div>
						</div>
						<p className='text-lg text-primary text-center'>
							We are sorry but we were unable to find any Working Genius Assessments for{' '}
							<span className={'font-semibold'}>{email}</span>.
						</p>
						<AppButton
							className={'btn btn-primary w-full mt-4'}
							text={'LOGIN'}
							loading={loading}
							id={'wg-create-account-btn'}
							onClick={() => navigate('/client/request-assessments/', { replace: true })}
						/>
						<div className={'flex items-center justify-center'}>
							<span className={'text-gray-500 text-tiny mt-1 text-center'}>
								It looks like you have an existing account. Login and then request a copy of all your Working Genius Assessments.
							</span>
						</div>
					</div>
				)}

				{!error.message && assessments.length === 0 && (
					<AppText
						text={
							'Provide the email you used prior to taking the assessment to retrieve or share your report.'
						}
						className='text-center mb-6'
						style={{ color: "#6b7280", fontSize: "0.875rem" }}
					/>
				)}

				{assessments && assessments.length > 0 && (
					<>
						<AppText text={'We located your Working Genius Assessments! '} className='text-center mb-6' />
						<p className={'mb-2'}>Your Recent Assessments</p>
						{assessments.map((assessment, key) => {
							const isGroup = assessment.type === 'group';
							return (
								<div className={'flex flex-1 items-center border-1 border-gray-300 p-4 mb-4'} key={key}>
									<WgIcon item={assessment} className={'text-primary text-2xl'} />
									<div className='flex flex-1 flex-col overflow-hidden mx-2'>
										<p className={'font-medium text-primary truncate break-all'}>
											{assessment.fname} {assessment.lname}
										</p>
										<p className={'text-primary truncate break-all'}>
											{isGroup ? assessment.group : 'Individual Assessment'}
										</p>
										<p className={'font-medium text-primary '}>
											Completed:{' '}
											<span className={'font-normal'}>{formatDate(assessment.completed)}</span>
										</p>
									</div>
								</div>
							);
						})}
						<AppButton
							className={'btn btn-primary w-full mt-4'}
							text={hasAccount ? 'LOGIN TO ACCESS ALL YOUR ASSESSMENTS' : 'Create an account'}
							loading={loading}
							id={'wg-create-account-btn'}
							onClick={_submit}
						/>
						<p className={'text-gray-500 text-tiny mt-1'}>
							{hasAccount
								? 'It looks like you have an existing account. Login and then request a copy of all your Working Genius Assessments.'
								: 'Create an Working Genius account clicking the above button. By creating an account you will be able to view all your results and share them with others.'}
						</p>
					</>
				)}
				{assessments.length === 0 && !hasAccount && (
					<form
						className={'w-full'}
						onSubmit={handleSubmit(search)}
						autoComplete='autocomplete_off_hack_xxfr4!k'
					>
						<AppInput
							{...register('email', {
								required: true,
								pattern: {
									value: emailRegex,
								},
							})}
							label={'Email address'}
							errors={errors}
							onBlur={(e) => {
								setValue('email', e.target.value.trim());
							}}
						/>

						<AppButton
							type='submit'
							className={'btn btn-primary w-full my-2'}
							text={error.message ? 'Try Again' : 'Continue'}
							loading={loading}
							id={'wg-find-assessment-submit-btn'}
						/>
						{hasAccount && (
							<div className={'flex items-center justify-center'}>
								<span
									className={'text-gray-500 text-tiny mt-1'}
									onClick={() => navigate('/client/request-assessments/', { replace: true })}
								>
									It looks like you have an existing account. Login and then request a copy of all your Working Genius Assessments.
								</span>
							</div>
						)}
						{!hasAccount && assessments.length > 0 && (
							<div className={'flex items-center justify-center'}>
								<span className={'text-lg text-primary cursor-pointer font-medium text-center'}>
									Create an Working Genius account clicking the above button. By creating an account,
									you will receive an email and the registration process will continue from there.
								</span>
							</div>
						)}
					</form>
				)}

				<ReCaptcha ref={captchaRef} />
			</div>
		);
	};

	return (
		<Layout title={'Find Your Assessments | The 6 Types Of Working Genius'} style={{ background: '#F5F5F5' }}>
			<div className={'container mx-auto'}>
				{renderContent()}
				{renderFooter()}
			</div>
		</Layout>
	);
};

export default Find;
