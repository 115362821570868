import React from 'react';
import { navigate, Link } from 'gatsby';
import { useLocation } from '@reach/router';

import Layout from '../../../components/Layout';
import { getQueryParams } from '../../../services/UtilityService';
import { TTGResetPassword } from '@ttg/ui-user-lib';
import config from '../../../constants/config';


const Reset = () => {
	const location = useLocation();
	const { token } = getQueryParams(location.search);

	if (!token) {
		navigate('/client/login/', { replace: true });
		return null;
	}

	return (
		<Layout style={{ background: '#F5F5F5' }}>
			<div className={'mx-auto mt-12'}>
				<TTGResetPassword
					apiEndpoint={config.ttg_url}
					captchaKey={config.captchaSiteKey}
					captchaEnabled={config.captchaEnabled}
					token={token}
					onResetFinish={() => navigate('/')}
					footer={
						<Link
							to={'/client/login/'}
							className={'flex flex-col items-center text-secondary font-medium mt-6 mb-2 cursor-pointer'}
						>
							Back to Login
						</Link>
					}
				/>
			</div>
		</Layout>
	);
};

export default Reset;
