import React from 'react';
import { wg_logo, ttg_logo_white } from '../../images';
import { formatNow } from "../../services/UtilityService";

const ToolkitFooter = () => {
	const currentYear = formatNow();
	return (
		<footer className='toolkit-footer'>
			<div className='toolkit-footer-layout'>
				<a className='toolkit-footer-logo cursor-pointer' href='https://dev.theworkinggenius.link/'>
					<img width='280' src={wg_logo} alt={'wg-logo'} />
				</a>

				<div className='toolkit-footer-text-container'>
					<p className='toolkit-footer-paragraph'> © {currentYear} Table Group </p>
				</div>

				<a className='toolkit-footer-power-by cursor-pointer' href='https://app.dev.thetablegroup.link/'>
					<img width='200' src={ttg_logo_white} alt={'ttg-logo'} />
				</a>
			</div>
		</footer>
	);
};

export default ToolkitFooter;
