import React from 'react';
import { cover_logo_de, report_footer_gear } from '../../../../images';
import { formatNow } from '../../../../services/UtilityService';

const Footer = ({ page }) => {
    return (
        <div className='PageFooter'>
            <div className='PageFooter__layout'>
                <div className='PageFooter__logo'>
                    <img src={cover_logo_de} alt={''} />
                </div>

                <div className='PageFooter__textContainer'>
                    <p className='PageFooter__paragraph'>
                        <a href={'https://www.workinggenius.com/'}>WorkingGenius.com</a>
                    </p>
                    <p className='PageFooter__paragraph'>
                        &copy; Copyright {formatNow()} The Table Group, Inc.
                    </p>
                </div>

                <div className='PageFooter__pageContainer'>
                    <p className='PageFooter__page'>
                        {page}
                        <span className='PageFooter__pageGear'>
                            <img src={report_footer_gear} alt={''} />
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
