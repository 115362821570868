import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import Resizer from 'react-image-file-resizer';

export const formatPrice = (price) => {
	const formattedPrice = price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	return `$${formattedPrice}`;
};

export const scrollToTop = () => {
	window.scrollTo(0, 0);
	window.postMessage({ name: 'onTtgScrollTop', scrollTop: 0 }, window.origin);
};

export const normalizePathName = (pathname) => (pathname.endsWith('/') ? pathname.slice(0, -1) : pathname);

const fullConfig = resolveConfig(tailwindConfig);

export const getBreakpointValue = (value) =>
	+fullConfig.theme.screens[value].slice(0, fullConfig.theme.screens[value].indexOf('px'));

export const getCurrentBreakpoint = () => {
	let currentBreakpoint;
	let biggestBreakpointValue = 0;
	for (const breakpoint of Object.keys(fullConfig.theme.screens)) {
		const breakpointValue = getBreakpointValue(breakpoint);
		if (breakpointValue > biggestBreakpointValue && window.innerWidth >= breakpointValue) {
			biggestBreakpointValue = breakpointValue;
			currentBreakpoint = breakpoint;
		}
	}
	return currentBreakpoint ? currentBreakpoint : 'sm';
};

export const emailRegex =
	/(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;


export const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const zipCodeRegex = /^[a-zA-Z0-9]+(\s?\-?){1}[a-zA-Z0-9]+$/;

export const getQueryParams = (url) => {
	const params = {};
	const qs = url.split('?')[1];
	if (!qs) {
		return params;
	}
	const pieces = qs.split('&');
	pieces.map((piece) => {
		const tmp = piece.split('=');
		params[tmp[0]] = tmp[1];
	});
	return params;
};

export const serializeQueryString = function (obj, prefix) {
	let str = [],
		p;
	for (p in obj) {
		// eslint-disable-next-line no-prototype-builtins
		if (obj.hasOwnProperty(p)) {
			let k = prefix ? prefix + '[' + p + ']' : p,
				v = obj[p];
			if (v !== null && v !== undefined) {
				str.push(
					typeof v === 'object'
						? serializeQueryString(v, k)
						: encodeURIComponent(k) + '=' + encodeURIComponent(v)
				);
			}
		}
	}
	return str.join('&');
};

export const getAuthCookie = () => {
	const token = Cookies.get('TTG_USER');
	if (token && token !== 'undefined') {
		return token;
	}
	return null;
};

export const getTablegroupAuthCookie = () => {
	let token = getAuthCookie();
	if (token) {
		const timestamp = dayjs().add(90, 'minute').unix();
		return `${token}@${timestamp}`;
	}
	return null;
};

export const setAuthCookie = (token) => {
	Cookies.set('TTG_USER', token, { expires: (1 / 24) * 1.5 });
};

export const updateAuthCookieExpiration = () => {
	Cookies.set('TTG_USER', Cookies.get('TTG_USER'), { expires: (1 / 24) * 1.5 });
};

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

export const formatNow = (format = 'YYYY') => dayjs().format(format);

export const formatDate = (date, format = 'MM/DD/YYYY hh:mm A', utc = false) => {
	if (utc) {
		return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format(format);
	}
	if (window.ttg_timezone) {
		return dayjs.utc(date, 'YYYY-MM-DD HH:mm:ss').tz(window.ttg_timezone).format(format);
	}

	return dayjs(date, 'YYYY-MM-DD HH:mm:ss').tz(dayjs.tz.guess()).format(format);
};

export const internal = (to) => {
	return /^\/(?!\/)/.test(to);
};

export const file = (to) => {
	return /\.[0-9a-z]+$/i.test(to);
};

export const splitArray = (inputArray, chunk) => {
	return inputArray.reduce((resultArray, item, index) => {
		const chunkIndex = Math.floor(index / chunk);
		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = [];
		}
		resultArray[chunkIndex].push(item);
		return resultArray;
	}, []);
};

export const checkCookiePresent = (location) => {
	const autoLoginPaths = [
			'client',
		'clientlogin',
		'clientcertification',
		'application-guide-product',
		'certification',
		'teamapplicationguide',
		'clientcertificationprework',
		'clientalumni-intensives'
	];
	const { search, pathname } = location;
	const { token } = getQueryParams(search);
	const strippedDownPath = pathname.replace(/\//g, '');

	if (autoLoginPaths.includes(strippedDownPath) && token) {
		return token.split('@')[0];
	}

	return false;
};


export const findIntersection = (array1, array2) => {
	return array1.filter(function (n) {
		return array2.indexOf(n) !== -1;
	});
};

const areObjectsIdentical = (object1, object2) => {
	return JSON.stringify(object1) === JSON.stringify(object2);
};

const downloadPdfFromBuffer = (arrayBuffer, fileName) => {
	const buffer = new Uint8Array(arrayBuffer);
	UtilityService.downloadFileFromBuffer(buffer, fileName, 'application/pdf');
};

const downloadFileFromBuffer = (buffer, fileName, type) => {
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.style = 'display: none';
	const url = window.URL.createObjectURL(new Blob([buffer], { type }));
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
};

const convertBase64ToBuffer = (base64) => {
	const binary_string = window.atob(base64);
	const len = binary_string.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i);
	}
	return bytes.buffer;
};

const clone = (item) => {
	if (!item) {
		return item;
	} // null, undefined values check

	var types = [Number, String, Boolean],
		result;

	// normalizing primitives if someone did new String('aaa'), or new Number('444');
	types.forEach(function (type) {
		if (item instanceof type) {
			result = type(item);
		}
	});

	if (typeof result == 'undefined') {
		if (Object.prototype.toString.call(item) === '[object Array]') {
			result = [];
			item.forEach(function (child, index) {
				result[index] = clone(child);
			});
		} else if (typeof item == 'object') {
			// testing that this is DOM
			if (!item.prototype) {
				// check that this is a literal
				if (item instanceof Date) {
					result = new Date(item);
				} else {
					// it is an object literal
					result = {};
					for (var i in item) {
						result[i] = clone(item[i]);
					}
				}
			} else {
				result = item;
			}
		} else {
			result = item;
		}
	}

	return result;
};

const isChildOf = (ref, event) => {
	try {
		return ref.current.contains(event.target);
	} catch (e) {
		return false;
	}
};

const getPageMetaData = (pathName) => {
	pathName = pathName.split('/').join('');

	const customPaths = {
		booklaunchevent: [
			{
				name: 'og:image',
				meta_attr: 'image',
				value: 'https://files.tablegroup.com/wp-content/uploads/2022/09/07052805/launch_event_share.jpg',
				key: 'general_image_meta',
			},
			{
				name: 'og:image:secure_url',
				meta_attr: 'og:image:secure_url',
				value: 'https://files.tablegroup.com/wp-content/uploads/2022/09/07052805/launch_event_share.jpg',
				key: 'og:image:secure_url',
			},
			{
				name: 'twitter:image',
				meta_attr: 'twitter:image',
				value: 'https://files.tablegroup.com/wp-content/uploads/2022/09/07052805/launch_event_share.jpg',
				key: 'twitter_image_meta',
			},
			{
				name: 'og:image:type',
				meta_attr: 'og:image:type',
				value: 'image/jpeg',
				key: 'og:image:type',
			},
			{
				name: 'og:image:width',
				meta_attr: 'og:image:width',
				value: '400',
				key: 'og:image:width',
			},
			{
				name: 'og:image:width',
				meta_attr: 'og:image:width',
				value: '300',
				key: 'og:image:height',
			},
		],
		launcheventsignup: [
			{
				name: 'og:image',
				meta_attr: 'image',
				value: 'https://files.tablegroup.com/wp-content/uploads/2022/09/07052805/launch_event_share.jpg',
				key: 'general_image_meta',
			},
			{
				name: 'twitter:image',
				meta_attr: 'twitter:image',
				value: 'https://files.tablegroup.com/wp-content/uploads/2022/09/07052805/launch_event_share.jpg',
				key: 'twitter_image_meta',
			},
		],
	};

	return (
		customPaths[pathName] ?? [
			{
				name: 'og:image',
				meta_attr: 'image',
				value: 'https://files.tablegroup.com/wp-content/uploads/2022/09/09090351/wg_sharelogo.jpg',
				key: 'general_image_meta',
			},
			{
				name: 'og:image:secure_url',
				meta_attr: 'og:image:secure_url',
				value: 'https://files.tablegroup.com/wp-content/uploads/2022/09/09090351/wg_sharelogo.jpg',
				key: 'og:image:secure_url',
			},
			{
				name: 'twitter:image',
				meta_attr: 'twitter:image',
				value: 'https://files.tablegroup.com/wp-content/uploads/2022/09/09090351/wg_sharelogo.jpg',
				key: 'twitter_image_meta',
			},
			{
				name: 'og:image:type',
				meta_attr: 'og:image:type',
				value: 'image/jpeg',
				key: 'og:image:type',
			},
			{
				name: 'og:image:width',
				meta_attr: 'og:image:width',
				value: '800',
				key: 'og:image:width',
			},
			{
				name: 'og:image:width',
				meta_attr: 'og:image:width',
				value: '400',
				key: 'og:image:height',
			},
		]
	);
};

const isEventLiveDatePassed = () => {
	return true;
};

const isUtcDateBefore = (time) => dayjs().utc().isBefore(dayjs.utc(time));

const isUtcDateBetween = (from, to) => dayjs().utc().isBetween(dayjs.utc(from), dayjs.utc(to));

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const niceBytes = (x) => {
	let l = 0,
		n = parseInt(x, 10) || 0;
	while (n >= 1024 && ++l) {
		n = n / 1024;
	}
	return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

const resizeImage = (image, w = 200, h = 100) => {
	const { type } = image;
	let format = type ? type.substring(type.indexOf('/') + 1).toUpperCase() : 'PNG';

	if (!['JPEG', 'PNG', 'WEBP'].includes(type)) {
		format = 'PNG';
	}

	return new Promise((resolve) => {
		Resizer.imageFileResizer(
			image,
			w,
			h,
			format,
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			'file'
		);
	});
};

const isValidEmail = (val) => {
	if (!val) {
		return false;
	}

	return !(!val.match(emailRegex) || val.match(emailRegex)[0] !== val);
};

const isEmailDuplicated = (emails, val, idx) =>
	emails.filter(({ value }, index) => value === val && index !== idx).length > 0;

const generateRandomString = (length) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

const UtilityService = {
	getCurrentBreakpoint,
	scrollToTop,
	getAuthCookie,
	getTablegroupAuthCookie,
	splitArray,
	findIntersection,
	areObjectsIdentical,
	downloadPdfFromBuffer,
	downloadFileFromBuffer,
	convertBase64ToBuffer,
	formatDate,
	serializeQueryString,
	clone,
	isChildOf,
	getPageMetaData,
	geniusColor: '#00a049',
	competencyColor: '#fbc331',
	frustrationColor: '#CB695B',
	isEventLiveDatePassed,
	niceBytes,
	resizeImage,
	isValidEmail,
	generateRandomString,
	isEmailDuplicated,
	isUtcDateBefore,
	isUtcDateBetween,
	normalizePathName,
};

export default UtilityService;
