import React from 'react';
import { Link } from 'gatsby';

import AppRIIcon from '../AppRIIcon';
import { ttg_logo_white, white_wg_logo } from '../../images';
import { formatNow } from '../../services/UtilityService';

const HomeFooter = ({ workshopItem = true }) => {
	return (
		<footer className={`page-footer dark wg-main-layout-footer`}>
			<div className='container'>
				<div className='footer-nav'>
					<div className='footer-info flex-1'>
						<a href='tel:+19252999700' className='contact-link' id='wg-home-footer-phone-link'>
							925.299.9700
						</a>
						<a
							href='mailto:support@workinggenius.com'
							className='contact-link'
							id={'wg-home-footer-mail-link'}
						>
							support@workinggenius.com
						</a>
						<p className='text-white text-base font-semibold uppercase mt-4'>Corporate Headquarters</p>
						<p className='text-white text-base font-thin text-center lg:text-left leading-7'>
							The Table Group, Inc
							<br />
							1211 Newell Ave, Suite 150
							<br />
							Walnut Creek, CA 94596
						</p>
					</div>
					<div className={'flex flex-col pb-4'}>
						<Link to='/' className='flex-1 pt-12 pb-3 flex items-center justify-center'>
							<img src={white_wg_logo} alt='Working Genius' width='350' />
						</Link>
						<div className={'flex flex-row justify-center'}>
							<Link to={'/policy/privacy-policy'} className={'footer-nav-link'}>
								Privacy Policy
							</Link>
							<Link to={'/policy/terms-of-service'} className={'footer-nav-link'}>
								Terms of Service
							</Link>
						</div>
					</div>

					<div className='footer-links flex-1'>
						<Link id={'wg-footer-about-link'} to={'/about'} className={'footer-nav-link'}>
							About Working Genius
						</Link>
						<Link id={'wg-footer-book-link'} to={'/book'} className={'footer-nav-link'}>
							The book
						</Link>
						{workshopItem && (
							<Link id={'wg-footer-workshop-link'} to={'/workshop'} className={'footer-nav-link'}>
								Request a workshop
							</Link>
						)}
						<Link
							id={'wg-footer-get-certified-link'}
							to={'/client/certification'}
							className={'footer-nav-link'}
						>
							Get certified
						</Link>
						<Link id={'wg-footer-faq-link'} to={'/faq'} className={'footer-nav-link'}>
							FAQS
						</Link>
					</div>
				</div>
				<div className='home-client-footer'>
					<div className='home-footer-socials'>
						<a href='https://www.linkedin.com/company/thetablegroup/' target='_blank'>
							<AppRIIcon icon='linkedin-box-fill' className='text-2xl text-white' />
						</a>
					</div>
					<div className='footer-home-copy'>
						<p className='book-p'>&copy; {formatNow()} The Table Group, Inc.</p>
					</div>
					<a
						href='https://www.tablegroup.com/'
						target='_blank'
						rel='noreferrer'
						className='flex-1 flex justify-center md:justify-end'
					>
						<img src={ttg_logo_white} alt='The Table Group' width='200' />
					</a>
				</div>
			</div>
		</footer>
	);
};

export default HomeFooter;
