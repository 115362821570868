import React from 'react';
import AppRIIcon from '../AppRIIcon';
import dayjs from 'dayjs';
import MarkAsReadToggle from './MarkAsReadToggle';
import MarkAsReadToggleMobile from './MarkAsReadToggleMobile';

const WGTeamNotificationsItem = ({ notification, onStatusChange }) => {
	const isRead = notification.read;

	return (
		<div className={`flex flex-row relative p-2 mb-4 ${isRead ? '' : 'bg-blue-100'}`}>
			<AppRIIcon icon={'settings-4-line'} className={'text-3xl text-primary'} />
			<div className={'flex flex-1 flex-col ml-2'}>
				<p
					className={'text-lg font-medium mb-2 mr-2'}
					dangerouslySetInnerHTML={{ __html: notification.title }}
				/>
				<p className={'text-normal mb-2 mr-2'} dangerouslySetInnerHTML={{ __html: notification.text }} />
				<p className={'text-tiny text-gray-600'}>{dayjs(notification.notification_date).fromNow()}</p>
			</div>
			<div className={'hidden md:block'}>
				<MarkAsReadToggle notification={notification} onComplete={onStatusChange} />
			</div>
			<div className={'block absolute top-0 right-0 m-1 md:hidden'}>
				<MarkAsReadToggleMobile notification={notification} onComplete={onStatusChange} />
			</div>
		</div>
	);
};

export default WGTeamNotificationsItem;
