import React from 'react';
import AppRIIcon from '../AppRIIcon';
import MarkAsReadToggle from './MarkAsReadToggle';
import MarkAsReadToggleMobile from './MarkAsReadToggleMobile';
import { navigate } from 'gatsby';
import NotificationService from '../../services/NotificationService';
import DefaultNotification from './Templates/DefaultNotification';
import SharedAssessmentNotification from './Templates/SharedAssessmentNotification';
import CompletedAssessmentNotification from './Templates/CompletedAssessmentNotification';
import dayjs from 'dayjs';
import AssessmentRequestNotification from './Templates/AssessmentRequestNotification';
import { useUser } from '../../hooks/UserProvider';

const NotificationItem = ({ notification, onStatusChange, toggleWidgetVisibility }) => {
	const isRead = notification.read;
	const { hasPermission } = useUser();

	const changeNotificationReadStatus = (to) => {
		toggleWidgetVisibility(false);
		if (to) {
			navigate(to);
		}
		if (hasPermission('NOTIFICATION.PUT'))
			NotificationService.toggleMarkAsRead({ id: notification.id, read: true }).then(() => {
				onStatusChange(true);
			});
	};

	const renderContent = () => {
		switch (notification.code) {
			case 'assessment_shared':
				return (
					<SharedAssessmentNotification
						notification={notification}
						changeNotificationReadStatus={changeNotificationReadStatus}
					/>
				);
			case 'assessment_finished':
				return (
					<CompletedAssessmentNotification
						notification={notification}
						changeNotificationReadStatus={changeNotificationReadStatus}
					/>
				);
			case 'assessment_request':
				return (
					<AssessmentRequestNotification
						notification={notification}
						changeNotificationReadStatus={changeNotificationReadStatus}
					/>
				);
			default:
				return (
					<DefaultNotification
						notification={notification}
						changeNotificationReadStatus={changeNotificationReadStatus}
					/>
				);
		}
	};

	return (
		<div className={`flex flex-row relative p-2 mb-4 ${isRead ? '' : 'bg-blue-100'}`}>
			<AppRIIcon icon={'file-list-2-line'} className={'text-3xl text-primary'} />
			<div className={'flex flex-1 flex-col ml-2'}>
				{renderContent()}
				<p className={'text-tiny text-gray-600'}>{dayjs(notification.notification_date).fromNow()}</p>
			</div>
			<div className={'hidden md:block'}>
				<MarkAsReadToggle notification={notification} onComplete={onStatusChange} />
			</div>
			<div className={'block absolute top-0 right-0 m-1 md:hidden'}>
				<MarkAsReadToggleMobile notification={notification} onComplete={onStatusChange} />
			</div>
		</div>
	);
};

export default NotificationItem;
